<template>
  <div class="container">
    <MobileHeaderBack></MobileHeaderBack>
    <div v-if="isMobile">
      <img src="../../../static/images/signup_bg_webapp_1.png" />
    </div>
    <div class="signup-wrapper">
      <SignupThemeInfo v-if="!isPopUp"></SignupThemeInfo>
      <div class="signup-form__wrapper">
        <div class="w-100">
          <div class="signup-form__title mb-main">
            {{ $t("FORGOT_PASSWORD") }}?
          </div>
          <div class="progressbar-wrapper mb-main">
            <ul class="progressbar">
              <li
                :class="{ active: currentForgetPwdStep.step >= o.step }"
                v-for="(o, index) in forgetPwdStep"
                :key="index"
              >
                <span>{{ o.step }}</span>
              </li>
            </ul>
          </div>
          <div class="font-m tc mb-main">
            <span>{{ $t(currentForgetPwdStep.title) }}</span>
          </div>

          <div class="signin-info error" v-if="showInfoErrorMsg">
            <img
              class="mr-1rem"
              @click="showInfoErrorMsg = false"
              src="../../../static/images/icons/icon_close.png"
            />
            <span class="font-m">{{ $t("LOGIN_INFO_ERROR") }}</span>
          </div>
          <div v-if="currentForgetPwdStep.step === 1">
            <!-- phone number -->
            <div class="form-group">
              <label class="form-label">{{ $t("PHONE_NUMBER") }}</label>
              <div class="display-flex relative">
                <div
                  class="display-flex-center signup-form__phone-prefix"
                  @click="handleDropdown($event)"
                >
                  <span class="mr-1rem"
                    >+{{ selectedCountry.callingCode }}</span
                  >
                  <img
                    src="../../../static/images/icons/icon_arrow_down_white.png"
                  />
                </div>
                <Dropdown
                  ref="dropdownRef"
                  :data="countryList"
                  @dropDownChange="dropDownChange($event)"
                ></Dropdown>
                <input
                  class="form-control"
                  type="text"
                  pattern="[0-9]*"
                  v-model="step1.inputPhoneNumber"
                  v-on:keyup="inputKeydown"
                  v-on:keyup.enter="handleNext()"
                  :placeholder="$t('PHONE_NUMBER')"
                />
              </div>
              <div class="form-tips">
                <div>
                  (+{{ selectedCountry.callingCode }})
                  <span v-if="selectedCountry.callingCode === 66"
                    >098765432</span
                  >
                  <span v-else>123456789</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentForgetPwdStep.step === 2">
            <!-- phone number -->
            <div class="form-group">
              <label class="form-label">{{ $t("OTP") }}</label>
              <div class="display-flex">
                <input
                  class="form-control mr-1rem flex-3"
                  type="password"
                  v-model="step2.inputOTPValue"
                  v-on:keyup="step2InputOTPEvent"
                  :placeholder="$t('ENTER_OTP')"
                />
                <div class="">
                  <!-- <button v-if="otp.isShowSendOTP" class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableSendOtpBtn }" @click="handleGetSignupCode('send')">{{$t('SEND_OTP')}}</button> -->
                  <button
                    v-if="step2.isShowSentOTP"
                    class="form-control signup-form__disable"
                    :class="{ 'signup-form__enable': step2.enableSendBtn }"
                    @click="step2HandleOtpCode('send')"
                  >
                    <span>{{ $t("SEND_OTP") }}</span>
                  </button>
                  <button
                    v-else
                    class="form-control signup-form__disable"
                    :class="{ 'signup-form__enable': step2.enableResendBtn }"
                    @click="step2HandleOtpCode('resend')"
                  >
                    <span>{{ $t("RESEND") }}</span
                    ><span v-if="step2.isShowResetTime">
                      ({{ step2.currentResetTime }})s</span
                    >
                  </button>
                </div>
              </div>
              <div class="form-tips">
                <div v-show="step2.isShowErrMsgOTPInput" class="error">
                  {{ $t("PLEASE_ENTER_CORRECT_OTP") }}
                </div>
              </div>
            </div>

            <div class="signup-form__recaptcha">
              <vue-recaptcha
                ref="invisibleRecaptcha"
                @verify="onVerifyRecaptcha"
                :sitekey="recaptcha.siteKey"
              ></vue-recaptcha>
            </div>
          </div>
          <div v-if="currentForgetPwdStep.step === 3">
            <!-- password -->
            <div class="form-group">
              <label class="form-label">{{ $t("PASSWORD") }}</label>
              <div class="form-group__container">
                <input
                  class="form-control"
                  :type="step3.showPassword ? 'text' : 'password'"
                  v-model="step3.inputPassword"
                  minlength="8"
                  maxlength="20"
                  v-on:keyup="step3InputPasswordEvent"
                  :placeholder="$t('ENTER_PASSWORD')"
                />
                <div
                  class="display-flex-center form-icon pointer"
                  @click="step3.showPassword = !step3.showPassword"
                >
                  <img
                    v-if="step3.showPassword"
                    src="../../../static/images/icons/icon_close_pwd.png"
                  />
                  <img
                    v-else
                    src="../../../static/images/icons/icon_show_pwd.png"
                  />
                </div>
              </div>
              <div class="form-tips">
                <div v-if="step3.isShowErrMsgPasswordInput" class="error">
                  {{ $t("FORGET_PASSWORD_ERR_MSG") }}
                </div>
                <div v-else>{{ $t("FORGET_PASSWORD_MSG") }}</div>
              </div>
            </div>
            <!-- confirm password -->
            <div class="form-group signup-form__password">
              <label class="form-label">{{ $t("COMFIRM_PASSWORD") }}</label>
              <div class="form-group__container">
                <input
                  class="form-control"
                  :type="step3.showConfirmPassword ? 'text' : 'password'"
                  v-model="step3.inputConfirmPassword"
                  minlength="8"
                  maxlength="20"
                  v-on:keyup="step3InputConfirmPasswordEvent"
                  :placeholder="$t('ENTER_PASSWORD')"
                />
                <div
                  class="display-flex-center form-icon pointer"
                  @click="
                    step3.showConfirmPassword = !step3.showConfirmPassword
                  "
                >
                  <img
                    v-if="step3.showConfirmPassword"
                    src="../../../static/images/icons/icon_close_pwd.png"
                  />
                  <img
                    v-else
                    src="../../../static/images/icons/icon_show_pwd.png"
                  />
                </div>
              </div>
              <div class="form-tips">
                <div
                  v-if="step3.isShowErrMsgConfirmPasswordInput"
                  class="error"
                >
                  {{ $t("FORGET_CONFIRM_PASSWORD_ERR_MSG") }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-signup__btn-wrapper">
            <div class="mb-1rem">
              <button
                class="form-control signup-form__disable"
                :class="{ 'signup-form__enable': isNextBtnEnable }"
                @click="handleNext()"
              >
                {{ $t("NEXT") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile">
      <img src="../../../static/images/signup_bg_webapp_2.png" />
    </div>
  </div>
</template>

<script>
const SIGNUP_METHOD = "PHONE";

import Footer from "@/components/footer/Footer.vue";
import SignupThemeInfo from "@/components/user/SignupThemeInfo.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import MobileHeaderBack from "@/components/mobile/HeaderBack.vue";
import { VueRecaptcha } from "vue-recaptcha";

import config from "@/js/config.js";

import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    isPopUp: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SignupThemeInfo,
    Dropdown,
    Footer,
    MobileHeaderBack,
    VueRecaptcha,
  },
  data() {
    return {
      countryList: config.countryList,
      selectedCountry: {
        callingCode: "",
      },
      isNextBtnEnable: false,
      alertMsg: "",

      curentIndex: 1,
      currentForgetPwdStep: {
        step: null,
        title: "",
      },
      forgetPwdStep: [
        {
          title: "ENTER_PHONE_NUMBER",
          step: 1,
        },
        {
          title: "ENTER_OTP",
          step: 2,
        },
        {
          title: "SET_NEW_PASSWORD",
          step: 3,
        },
      ],
      step1: {
        inputPhoneNumber: "",
      },
      step2: {
        isShowSentOTP: true,
        enableSendBtn: false,
        resetDuration: 120, //120s
        currentResetTime: null,
        enableResendBtn: false,
        isShowResetTime: false,
        inputOTPValue: "",
        isShowErrMsgOTPInput: false,
        validateOTPObj: {},
      },
      step3: {
        inputPassword: "",
        inputConfirmPassword: "",
        showPassword: false,
        showConfirmPassword: false,
        isShowErrMsgPasswordInput: false,
        isShowErrMsgConfirmPasswordInput: false,
        isError: true,
      },
      recaptcha: {
        value: "",
        siteKey: config.recaptchaSiteKey,
      },
    };
  },
  computed: {
    ...mapGetters(["currentLocale", "isMobile"]),
  },
  created() {},
  beforeUnmount() {
    //window.removeEventListener('keydown', this.listenKeypressEvent)
  },
  mounted() {
    //window.addEventListener('keydown', this.listenKeypressEvent);
    this.init();
  },
  methods: {
    ...mapActions([
      "postForgotPasswordGetCode",
      "getCurrentLocation",
      "postForgotPasswordValidate",
      "postChangePassword",
      "setUserData",
      "toastrChangeEvent",
      "getLoginMethod",
      "currentLocaleChangeEvent",
    ]),
    init() {
      this.getCurrentLocationData();

      this.currentForgetPwdStep = this.forgetPwdStep[this.curentIndex - 1];
    },

    onVerifyRecaptcha: function(response) {
      this.recaptcha.value = response;
      if (this.recaptcha.value !== "") {
        this.step2.enableSendBtn = true;

        if (!this.step2.isShowResetTime) {
          this.step2.enableResendBtn = true;
        }

        //this.handleGetSignupCode();
      }
    },

    async getCurrentLocationData() {
      let matchSelectedCountry = false;

      let timeZone = this.$tools.getCurrentTimeZone();
      // // detect current location and selected current location country calling code
      setTimeout(() => {
        this.countryList.forEach((x) => {
          if (x.timeZone === timeZone) {
            this.$refs.dropdownRef.handleDropdownSelect(x);
            matchSelectedCountry = true;
            return false;
          }
        });

        //otherwise default to thailand calling code

        if (!matchSelectedCountry) {
          this.$refs.dropdownRef.handleDropdownSelect(this.countryList[0]);
        }
      }, 100);
    },

    async handleNext() {
      if (this.curentIndex === 1) {
        let params = {
          method: SIGNUP_METHOD,
          username:
            this.selectedCountry.callingCode + this.step1.inputPhoneNumber,
        };

        const result = await this.getLoginMethod(params);

        if (result.result.businessCode === 0) {
          this.currentForgetPwdStep = this.forgetPwdStep[this.curentIndex];
          this.curentIndex++;

          this.step2.isShowSentOTP = true;
          this.step2.enableSendBtn = false;
        } else {
          let params = {
            isOpen: true,
            msg: result.result.message,
            type: "info",
          };

          this.toastrChangeEvent(params);
        }
        // let that = this;
        // let params = {
        //     method: SIGNUP_METHOD,
        //     username: this.selectedCountry.callingCode + this.step1.inputPhoneNumber,
        //     'g-recaptcha-response':this.recaptcha.value
        // }

        // const result = await this.postForgotPasswordGetCode(params);

        //   if (result.result.businessCode === 0) {
        //     this.isNextBtnEnable = false;
        //     this.currentForgetPwdStep = this.forgetPwdStep[this.curentIndex];
        //     this.curentIndex++;

        //     //step OTP (step:2)
        //     this.step2.currentResetTime = this.step2.resetDuration;
        //     // countdown each second
        //    let interval = setInterval(() => {
        //         if (this.step2.currentResetTime > 0) {// only positive number
        //             this.step2.currentResetTime--;
        //         }

        //         //enable button when reset is 0
        //         if(this.step2.currentResetTime === 0) {
        //             this.step2.enableResendBtn = true;
        //             clearInterval(interval);
        //         }
        //     }, 1000);

        //   } else {
        //     let params={
        //         isOpen:true,
        //         msg:result.result.message,
        //         type:'info'
        //     }
        //     this.toastrChangeEvent(params)
        //   }
      } else if (this.curentIndex === 2) {
        let params = {
          method: SIGNUP_METHOD,
          username:
            this.selectedCountry.callingCode + this.step1.inputPhoneNumber,
          code: this.step2.inputOTPValue,
        };

        const result = await this.postForgotPasswordValidate(params);

        this.step2.validateOTPObj = result.result.data;
        if (result.result.businessCode === 0) {
          //sucuess
          this.currentForgetPwdStep = this.forgetPwdStep[this.curentIndex];
          this.curentIndex++;
          this.isNextBtnEnable = false;
        } else {
          this.step2.isShowErrMsgOTPInput = true;
        }
      } else if (this.curentIndex === 3) {
        let params = {
          token: this.step2.validateOTPObj.token,
          confirmNewPassword: this.step3.inputPassword,
          newPassword: this.step3.inputConfirmPassword,
        };

        const result = await this.postChangePassword(params);

        if (result.result.businessCode === 0) {
          //sucuess

          this.setUserData(result.result.data);
          let locale = `${
            result.result.data.preferredLanguage === "vi"
              ? "vn"
              : result.result.data.preferredLanguage
          }`;
          if (this.isPopUp) {
            //this.$root.toastrMsg ='PASSWORD_CHANGED';
            //this.$root.$refs.toastrRef.handleOpen();
            this.$emit("loginSucuessEvent", "forgetPassword");
            this.currentLocaleChangeEvent(locale);
            let params = {
              isOpen: true,
              msg: "PASSWORD_CHANGED",
            };
            this.toastrChangeEvent(params);
          } else {
            let callBackUrl = "";
            let callBackUrlArray = [];
            let addRedirectWord = "";

            let routeCallBackUrl = this.$route.query.callBackUrl;
            if (routeCallBackUrl) {
              //always use the locale that come from user info api
              callBackUrlArray = routeCallBackUrl.split("/");
              callBackUrlArray[1] = locale;
              callBackUrl = callBackUrlArray.join("/");

              if (callBackUrl.includes("?")) {
                addRedirectWord = "&";
              } else {
                addRedirectWord = "?";
              }
              callBackUrl =
                callBackUrl + `${addRedirectWord}redirectFrom=forgetPassword`;
            }

            if (callBackUrl === "") {
              this.$router.push(`/${locale}`);
            } else {
              this.$router.push(`${callBackUrl}`);
            }

            if (routeCallBackUrl !== "") {
              if (callBackUrlArray[2] !== "event") {
                setTimeout(() => {
                  let params = {
                    isOpen: true,
                    msg: "PASSWORD_CHANGED",
                  };
                  this.toastrChangeEvent(params);
                  // this.$root.toastrMsg ='PASSWORD_CHANGED';
                  // this.$root.$refs.toastrRef.handleOpen();
                }, 1000);
              }
            }
          }
        } else {
          let params = {
            isOpen: true,
            msg: result.result.message,
            type: "info",
          };
          this.toastrChangeEvent(params);
          // this.$root.toastrMsg = result.result.message;
          // this.$root.toastrType = 'info';
          // this.$root.$refs.toastrRef.handleOpen();
        }
      }
    },

    async step2HandleOtpCode(action) {
      let params = {
        method: SIGNUP_METHOD,
        username:
          this.selectedCountry.callingCode + this.step1.inputPhoneNumber,
        "g-recaptcha-response": this.recaptcha.value,
      };

      const result = await this.postForgotPasswordGetCode(params);

      if (result.result.businessCode === 0) {
        this.step2.isShowSentOTP = false;

        //step OTP (step:2)
        this.step2.enableResendBtn = false;
        this.step2.isShowResetTime = true;

        this.step2.currentResetTime = this.step2.resetDuration;
        // countdown each second
        let interval = setInterval(() => {
          if (this.step2.currentResetTime > 0) {
            // only positive number
            this.step2.currentResetTime--;
          }

          //enable button when reset is 0
          if (this.step2.currentResetTime === 0) {
            clearInterval(interval);
            this.$refs.invisibleRecaptcha.reset();
            this.recaptcha.value = "";
            this.step2.enableResendBtn = false;
            this.step2.isShowResetTime = false;
          }
        }, 1000);
      } else {
        let params = {
          isOpen: true,
          msg: result.result.message,
          type: "info",
        };
        this.toastrChangeEvent(params);
      }
    },

    step2InputOTPEvent(e) {
      if (this.step2.inputOTPValue.length > 0) {
        this.isNextBtnEnable = true;
      } else {
        this.isNextBtnEnable = false;
      }
      this.step2.isShowErrMsgOTPInput = false;
    },

    step3InputPasswordEvent(e) {
      let minLength = 8;
      let maxLength = 20;

      if (
        this.$tools.noSymbolVerification(this.step3.inputPassword) &&
        this.step3.inputPassword.length >= minLength &&
        this.step3.inputPassword.length <= maxLength
      ) {
        this.step3.isShowErrMsgPasswordInput = false;
        this.step3.isError = false;
      } else {
        this.step3.isShowErrMsgPasswordInput = true;
        this.step3.isError = true;
      }
      if (this.step3.inputConfirmPassword === this.step3.inputPassword) {
        this.step3.isShowErrMsgConfirmPasswordInput = false;
        this.step3.isError = false;
      }
      this.step3VerifyAllInput();
    },

    step3InputConfirmPasswordEvent() {
      if (this.step3.inputConfirmPassword === this.step3.inputPassword) {
        this.step3.isShowErrMsgConfirmPasswordInput = false;
        this.step3.isError = false;
      } else {
        this.step3.isShowErrMsgConfirmPasswordInput = true;
        this.step3.isError = true;
      }
      this.step3VerifyAllInput();
    },

    step3VerifyAllInput() {
      if (
        this.step3.inputConfirmPassword.length > 0 &&
        this.step3.inputPassword
      ) {
        if (
          this.step3.isShowErrMsgPasswordInput ||
          this.step3.isShowErrMsgConfirmPasswordInput
        ) {
          this.isNextBtnEnable = false;
        } else {
          this.isNextBtnEnable = true;
        }
      } else {
        this.isNextBtnEnable = false;
      }
    },

    handleDropdown(e) {
      this.$refs.dropdownRef.handleOpen(e);
    },
    dropDownChange(e) {
      this.selectedCountry = e;
    },

    inputKeydown(e) {
      this.step1.inputPhoneNumber = this.$tools.onlyNumber(
        this.step1.inputPhoneNumber
      );

      let minLength = 6;
      let maxLength = 10;

      let inputPhoneNumberLength = this.step1.inputPhoneNumber.length;

      if (inputPhoneNumberLength > maxLength) {
        this.step1.inputPhoneNumber = this.step1.inputPhoneNumber.slice(
          0,
          maxLength
        );
      }

      if (
        inputPhoneNumberLength >= minLength &&
        inputPhoneNumberLength <= maxLength
      ) {
        this.isNextBtnEnable = true;
      } else {
        this.isNextBtnEnable = false;
      }
    },
    // listenKeypressEvent(e) {
    //     if (e.key === "Enter") {
    //        this.handleNext();
    //     }
    // }
  },
};
</script>

<style></style>
